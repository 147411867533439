<template>
	<div class="base-info">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px">
			<el-form-item label="注册手机号:" prop="RegisterPhone">
				<el-input v-model="ruleForm.RegisterPhone" style="width: 350px;" maxlength="11" placeholder="请输入11位手机号"></el-input>
				<div class="form-item-tip">该手机号将用于支付商户后台登录以及验证码等相关短信接收，请填写正确的手机号。
					注册手机号不支持修改，且1个手机号仅可注册1个Adapay支付商户号</div>
			</el-form-item>
			<el-form-item label="联系人姓名:" prop="ContactName">
				<el-input v-model="ruleForm.ContactName" style="width: 350px;" maxlength="16" placeholder="请输入真实姓名"></el-input>
				<div class="form-item-tip">联系人姓名不支持修改</div>
			</el-form-item>
			<el-form-item label="联系人手机号:" prop="ContactPhone">
				<el-input v-model="ruleForm.ContactPhone" style="width: 350px;" maxlength="11" placeholder="请输入11位手机号"></el-input>
				<div class="form-item-tip">该手机号将作为您后续在线申请交易手续费发票的登录帐号，也会接收部分系统通知。
					可以使用运营者或其他人手机号。联系手机号后续可登录支付商户后台进行修改</div>
			</el-form-item>
			<el-form-item label="电子邮箱:" prop="Email">
				<el-input v-model="ruleForm.Email" style="width: 350px;" maxlength="100" placeholder="请输入邮箱"></el-input>
				<div class="form-item-tip">请输入可正常接收邮件的邮箱地址，用于接收在线申请交易手续费发票等其他系统通知</div>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	
	import {validatePhone, validateEmail} from '@/utils/validate';
	
	export default {
		props: {
			configData: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		data() {

			var checkPhone = (rule, value, callback) => {
				if (!validatePhone(this.ruleForm[rule.field])) {
					return callback(new Error('请输入11位注册手机号'));
				} else {
					return callback();
				}
			};
			var checkEmail = (rule, value, callback) => {
				if (!validateEmail(this.ruleForm[rule.field])) {
					return callback(new Error(''));
				} else {
					return callback();
				}
			};

			return {
				ruleForm: {
					RegisterPhone: '',
					ContactName: '',
					ContactPhone: '',
					Email: ''
				},
				rules: {
					RegisterPhone: [{
						required: true,
						trigger: 'blur',
						validator: checkPhone
					}],
					ContactName: [{
						required: true,
						trigger: 'blur',
						message: '请输入联系人姓名'
					}],
					ContactPhone: [{
						required: true,
						trigger: 'blur',
						message: '请输入联系人11位手机号',
						validator: checkPhone
					}],
					Email: [{
						required: true,
						trigger: 'blur',
						message: '请输入正确的邮箱地址',
						validator: checkEmail
					}],
				}
			}
		},
		watch: {
			configData(){
				this.init();
			}
		},
		created() {
			this.init();
		},
		methods: {
			init(){
				for (let item in this.configData){
					// console.log(item)
					this.ruleForm[item] = this.configData[item];
				}
			},
			submit(callback){
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						callback && callback(true, this.ruleForm);
					} else {
						callback && callback(false);
				  }
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.base-info{
		padding-top: 30px;
	}
	.form-item-tip{
		font-size: 12px;
		margin-top: 3px;
		color: #999;
		height: 20px;
		line-height: 20px;
	}
</style>
