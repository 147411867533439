<template>
	<div class="base-info">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px">
			<div class="form-item-tip">请使用企业法人身份信息开出的银行卡，并且请确保该银行卡4要素完全匹配
				（即持卡人姓名、开户手机号、持卡人身份证及银行卡号均为企业法人的信息），否则将
				<span style="color:#f56c6c">进件失败</span>
			</div>
			<el-form-item label="银行账户类型:" prop="BankAccountType">
				<el-select v-model="ruleForm.BankAccountType" placeholder="请选择银行账户类型" style="width: 350px;">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="结算银行:" prop="BankCode">
				<el-select v-model="ruleForm.BankCode" filterable @change="bankChange" placeholder="请输入银行名称,如工商银行" style="width: 350px;">
					<el-option v-for="item in bankList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="银行卡号:" prop="CardNo">
				<el-input v-model="ruleForm.CardNo" style="width: 350px;" maxlength="30" placeholder="请输入银行卡号"></el-input>
			</el-form-item>
			<el-form-item label="开户姓名:" prop="CardHolderName">
				<el-input v-model="ruleForm.CardHolderName" style="width: 350px;" maxlength="50" placeholder="请输入开户人姓名"></el-input>
			</el-form-item>
			<el-form-item label="开户银行省份:" prop="BankProvinceCode">
				<el-select v-model="ruleForm.BankProvinceCode" @change="provinceChange" placeholder="请选择开户银行省份" style="width: 350px;">
					<el-option v-for="item in provinceList" :key="item.value" :label="item.title" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="开户银行地区:" prop="BankCityCode">
				<el-select v-model="ruleForm.BankCityCode" @change="cityChange" placeholder="请选择开户银行地区" style="width: 350px;">
					<el-option v-for="item in cityList" :key="item.value" :label="item.title" :value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {
		validatePhone,
		validateEmail
	} from '@/utils/validate';
	
	import axios from 'axios'

	export default {
		props: {
			configData: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		data() {

			var checkPhone = (rule, value, callback) => {
				if (!validatePhone(this.ruleForm[rule.field])) {
					return callback(new Error('请输入11位注册手机号'));
				} else {
					return callback();
				}
			};
			var checkEmail = (rule, value, callback) => {
				if (!validateEmail(this.ruleForm[rule.field])) {
					return callback(new Error(''));
				} else {
					return callback();
				}
			};

			return {
				options: [{
					value: '1',
					label: '对公'
				}, {
					value: '2',
					label: '对私'
				}],
				
				bankList: [],
				
				provinceList: [],
				cityList: [],
				
				ruleForm: {
					BankAccountType: '',
					BankCode: '',
					BankName: '',
					CardNo: '',
					CardHolderName: '',
					
					BankProvinceCode: '',
					BankProvince: '',
					
					BankCityCode: '',
					BankCity: '',
					
				},
				rules: {
					BankAccountType: [{
						required: true,
						trigger: 'change',
						message: '请选择结算银行账户类型'
					}],
					BankCode: [{
						required: true,
						trigger: 'change',
						message: '请选择结算银行'
					}],
					CardNo: [{
						required: true,
						trigger: 'blur',
						message: '请输入银行卡号'
					}],
					CardHolderName: [{
						required: true,
						trigger: 'blur',
						message: '请输入开户人姓名'
					}],
					BankProvinceCode: [{
						required: true,
						trigger: 'change',
						message: '请选择开户银行省份'
					}],
					BankCityCode: [{
						required: true,
						trigger: 'change',
						message: '请选择开户银行地区'
					}],
				}
			}
		},
		watch: {
			configData(){
				this.init();
			}
		},
		created() {
			this.getDataList();
			this.getBankList();
			this.init();
		},
		methods: {
			init(){
				for (let item in this.configData){
					if (this.configData[item]){
						this.ruleForm[item] = this.configData[item];
					}
				}
			},
			getBankList(){
				var that = this
				axios.get('http://cdn.dkycn.cn/JSON/melyshop/adapay_bank.json?v='+new Date().getTime(), {})
					.then(function(response) {
						var dt = response.data;
						that.bankList = dt;
					})
					.catch(function(error) {})
			},
			getDataList() {
				var that = this;
				axios.get('http://cdn.dkycn.cn/JSON/melyshop/jinjian.json?v='+new Date().getTime(), {})
					.then(function(response) {
						var dt = response.data;
						that.provinceList = dt;
						
						if (that.ruleForm.BankProvinceCode){
							that.provinceList.map(item=>{
								if (item.value == that.ruleForm.BankProvinceCode){
									that.cityList = item.cities;
								}
							})
						}
					})
					.catch(function(error) {})
			},
			provinceChange(val){
				this.provinceList.map(item=>{
					if (item.value == val){
						this.cityList = item.cities;
						this.ruleForm.BankProvince = item.title;
					}
				})
				this.ruleForm.BankCityCode = ''
			},
			cityChange(val){
				this.cityList.map(item=>{
					if (item.value == val){
						this.ruleForm.BankCity = item.title;
					}
				})
			},
			bankChange(val){
				this.bankList.map(item=>{
					if (item.value == val){
						this.ruleForm.BankName = item.label;
					}
				})
			},
			submit(callback){
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						callback && callback(true, this.ruleForm);
					} else {
						callback && callback(false);
				  }
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.base-info {
		padding-top: 30px;
	}

	.form-item-tip {
		font-size: 14px;
		margin-top: 3px;
		color: #999;
		line-height: 20px;
		margin:0px 0px 10px 55px;
		display: inline-block;
	}
</style>
