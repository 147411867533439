<template>
	<div class="submit-information" v-loading="pageloading">
		<div class="title">提交申请资料</div>
		
		<div class="step">
			<el-steps :active="active">
			  <el-step title="基本信息" :status="active >= 1 ? 'process' : 'wait'">
				  <div slot="icon" class="step-icon" @click="stepChange(1)">1</div>
			  </el-step>
			  <el-step title="商户信息" :status="active >= 2 ? 'process' : 'wait'">
				  <div slot="icon" class="step-icon" @click="stepChange(2)">2</div>
			  </el-step>
			  <el-step title="结算信息" :status="active >= 3 ? 'process' : 'wait'">
				  <div slot="icon" class="step-icon" @click="stepChange(3)">3</div>
			  </el-step>
			  <el-step title="开户进件" :status="active >= 4 ? 'process' : 'wait'">
				  <div slot="icon" class="step-icon">4</div>
			  </el-step>
			</el-steps>
		</div>
		
		<div class="set-information" v-if="active <= 3">
			<div class="info-item">
				<div class="info-title">基本信息</div>
				<baseinfo ref="baseinfo" :configData="baseInfoData"></baseinfo>
			</div>
			<div class="info-item">
				<div class="info-title">商户信息</div>
				<businessLicense ref="businessLicense" :configData="businessLicenseData"></businessLicense>
				<legalPerson ref="legalPerson" :configData="legalPersonData"></legalPerson>
			</div>
			<div class="info-item">
				<div class="info-title">结算信息</div>
				<bankInfo ref="bankInfo" :configData="bankInfoData"></bankInfo>
			</div>
		</div>
		<div class="set-information set-information-center" style="padding-top:30px" v-else>
			<div v-if="state==0">
				进件资料提交成功，商户进件中...
				<el-button type="text" @click="saveData">点击此处获取最新进件结果</el-button>
			</div>
			<div v-if="state==1">
				<div>进件失败</div>
				<div style="margin:10px 0px 0px 0px;color:#f56c6c">
					<span>失败原因：</span>
					<span>{{resultmsg}}</span>
				</div>
				<el-button type="text" @click="againInto">点击此处重新进件</el-button>
			</div>
			<div v-else-if="state==2">
				<div class="success-info-1">商户进件成功，
					<el-button type="text" @click="handleToSetInformation">点击此处前往补充商户证照资料：</el-button>
				</div>
				<div class="success-info-2">
					<div class="success-info-3">
						关于资料补充时间：请于<span style="color: #F56C6C;font-weight: bold;">5个工作日内</span>补充商户营业执照、法人证件、门头照等材料，
						并需通过审核，未提交材料或审核未通过将会关闭商户状态<span style="color: #F56C6C;">（关闭后需要更换手机号重新进件）</span>
					</div>
					<div class="success-info-3">关于审核时间：工作日17：30之前提交，当天审核</div>
					<div class="success-info-3">关于审核结果：审核结果将会通过短信发送至联系人手机号，若未审核通过，请及时处理</div>
				</div>
				
				<!-- <div class="btn-wraper">
					<el-button style="margin-right: 30px;height: 44px;width: 156px;" @click="handleToSet">稍后再补充</el-button>
					<el-button style="height: 44px;width: 156px;" type="primary" @click="handleToSetInformation">立即补充资料</el-button>
				</div> -->
			</div>
		</div>
		
		<div class="submit" v-if="active <= 3">
			<el-button type="primary" :loading="loading" @click="handleSubmit">提交申请资料</el-button>
		</div>
		
	</div>
</template>

<script>
	
	import baseinfo from './submitInformationComponents/baseinfo.vue';
	import businessLicense from './submitInformationComponents/businessLicense.vue';
	import legalPerson from './submitInformationComponents/legalPerson.vue';
	import bankInfo from './submitInformationComponents/bankInfo.vue';
	
	import {
		adapayMerchantEdit,
		adapayMerchantInfo
	} from '@/api/TurnTomySelf.js'
	
	export default {
		components: {
			baseinfo,
			businessLicense,
			legalPerson,
			bankInfo
		},
		data() {
			return {
				pageloading:false,
				active: 2,
				loading: false,
				baseInfoData: {
					RegisterPhone: '',
					ContactName: '',
					ContactPhone: '',
					Email: ''
				},
				businessLicenseData: {
					MerchantName: '',
					MerchantAbbrName: '',
					LicenseCode: '',
					BusinessAddressProvinceCode: '',
					BusinessAddressProvince : '',
					BusinessAddressCityCode: '',
					BusinessAddressCity: '',
					BusinessAddressDistrictCode: '',
					BusinessAddressDistrict: '',
					BusinessAddressDetail: '',
					AddressArr: [],
					MerchantTelAreaCode: '',
					MerchantTel: '',
				},
				legalPersonData: {
					CorporationName: '',
					CorporationPhone: '',
					CorporationCardId: '',
					CorporationCardIdStartDate: '',
					CorporationCardIdEndDate: ''
				},
				bankInfoData: {
					BankAccountType: '',
					BankCode: '',
					BankName: '',
					CardNo: '',
					CardHolderName: '',
					
					BankProvinceCode: '',
					BankProvince: '',
					
					BankCityCode: '',
					BankCity: '',
				},
				state:1,//0提交中  1提交失败  2提交成功
				resultmsg:'',
			}
		},
		created() {
			this.getData();
		},
		methods: {

			againInto(){
				this.baseInfoData.RegisterPhone = ''
				// console.log(this.baseInfoData,'123')
				this.active = 1
			},
			async getData(){
				const res = await adapayMerchantInfo();
				
				if (res.IsSuccess){
					
					var baseInfoData = JSON.parse(JSON.stringify(this.baseInfoData));
					for (let item in baseInfoData){
						baseInfoData[item] = res.Result[item];
					}
					if(this.$route.query.again){
						baseInfoData.RegisterPhone = ''
					}
					this.baseInfoData = baseInfoData;
					
					var businessLicenseData = JSON.parse(JSON.stringify(this.businessLicenseData));
					for (let item in businessLicenseData){
						businessLicenseData[item] = res.Result[item];
					}
					this.businessLicenseData = businessLicenseData;
					
					var legalPersonData = JSON.parse(JSON.stringify(this.legalPersonData));
					for (let item in legalPersonData){
						legalPersonData[item] = res.Result[item];
					}
					this.legalPersonData = legalPersonData;
					
					var bankInfoData = JSON.parse(JSON.stringify(this.bankInfoData));
					for (let item in bankInfoData){
						bankInfoData[item] = res.Result[item];
					}
					this.bankInfoData = bankInfoData;
					
					if (res.Result.BusinessAddressProvinceCode && res.Result.BusinessAddressCityCode && res.Result.BusinessAddressDistrictCode){
						this.businessLicenseData.AddressArr = [res.Result.BusinessAddressProvinceCode, res.Result.BusinessAddressCityCode, res.Result.BusinessAddressDistrictCode]
					}
					
				}
			},
			stepChange(e){
				if(e!=4){
					this.active = e
				}
				if (e===1){
					document.querySelector('.set-information').scrollTop = 0;
				}
				if (e===2){
					document.querySelector('.set-information').scrollTop = 360;
				}
				if (e===3){
					document.querySelector('.set-information').scrollTop = 900;
				}
			},
			handleSubmit(){
				this.getBaseinfo().then(res=>{
					this.baseInfoData = res;
					// console.log(res, 'getBaseinfo')
					return this.getBusinessLicense();
				}).then(res=>{
					this.businessLicenseData = res;
					// console.log(res, 'getBusinessLicense')
					return this.getLegalPerson();
				}).then(res=>{
					this.legalPersonData = res;
					// console.log(res, 'getLegalPerson')
					return this.getBankInfo();
				}).then(res=>{
					this.bankInfoData = res;
					// console.log(res, 'getBankInfo')
					
					this.saveData();
				});
			},
			saveData(){
				let params = {};
				
				for (let item in this.baseInfoData){
					params[item] = this.baseInfoData[item]
				}
				for (let item in this.businessLicenseData){
					params[item] = this.businessLicenseData[item]
				}
				for (let item in this.legalPersonData){
					params[item] = this.legalPersonData[item]
				}
				for (let item in this.bankInfoData){
					params[item] = this.bankInfoData[item]
				}
				
				var cardId = params.CorporationCardId + '';
				var strList = cardId.split('');
				if (strList[strList.length-1] == 'x'){
					strList[strList.length-1] = 'X';
				}
				params.CorporationCardId = strList.join('');
				
				// params.CorporationCardIdStartDate = this.getTimeStr(params.CorporationCardIdStartDate);
				// params.CorporationCardIdEndDate = this.getTimeStr(params.CorporationCardIdEndDate);
				
				// console.log(params)
				this.save(params);
			},
			async save(params){
				
				try{
					this.loading = true;
					this.active = 4
					this.state = 0
					// let data = params
					// data.noError = true
					const res = await adapayMerchantEdit(params);
					
					if (res.IsSuccess){
						this.state = 2
					}
				}catch(err){
						this.state = 1
						this.resultmsg = err
					// console.log(err)
				}finally{
					this.loading = false;
				}
				
			},
			getTimeStr(date){
				var year = date.getFullYear();
				var month = date.getMonth()+1;
				var day = date.getDate();
				var hour = date.getHours();
				var minute = date.getMinutes();
				var second = date.getSeconds();
				
				return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':')
			},
			getBaseinfo(){
				return new Promise((resolve, reject)=>{
					this.$refs['baseinfo'].submit((bol, data)=>{
						if (bol){
							// console.log(data, 'getBaseinfo')
							resolve(data)
						}else{
							document.querySelector('.set-information').scrollTop = 0;
						}
					})
				})
			},
			getBusinessLicense(){
				return new Promise((resolve, reject)=>{
					this.$refs['businessLicense'].submit((bol, data)=>{
						if (bol){
							console.log(data, 'getBusinessLicense')
							resolve(data)
						}else{
							document.querySelector('.set-information').scrollTop = 360;
						}
					})
				})
			},
			getLegalPerson(){
				return new Promise((resolve, reject)=>{
					this.$refs['legalPerson'].submit((bol, data)=>{
						if (bol){
							// console.log(data, 'getLegalPerson')
							resolve(data)
						}else{
							document.querySelector('.set-information').scrollTop = 860;
						}
					})
				})
			},
			getBankInfo(){
				return new Promise((resolve, reject)=>{
					this.$refs['bankInfo'].submit((bol, data)=>{
						if (bol){
							// console.log(data, 'getBankInfo')
							resolve(data)
						}else{
							document.querySelector('.set-information').scrollTop = 900;
						}
					})
				})
			},
			
			
			handleToSet(){
				this.$router.replace({
					name: 'settingPay'
				})
			},
			handleToSetInformation(){
				this.$router.replace({
					name: 'replenishInformation'
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.submit-information {
		
		position: absolute;
		left: 15px;
		top: 15px;
		right: 15px;
		bottom: 15px;
		background: #fff;
		
		display: flex;
		flex-direction: column;

		.title{
			flex: 0 0 auto;
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			background-color: #f8f8f9;
			font-size: 16px;
		}
		
		.step{
			flex: 0 0 auto;
			margin: 20px 30px;
			border-bottom: 1px solid #eee;
		}
		.step-icon{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			cursor: pointer;
			justify-content: center;
		}
		
		.set-information{
			flex: 1 1 auto;
			overflow: auto;
			padding-bottom: 30px;
			
			.info-item{
				margin-left: 30px;
				margin-right: 40px;
				.info-title{
					height: 30px;
					line-height: 30px;
					padding-left: 20px;
					background-color: #f8f8f9;
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
		.set-information-center{
			display: flex;
			justify-content: center;
		}
		
		.submit{
			flex: 0 0 auto;
			height: 60px;
			background-color: #fff;
			border-top: 1px solid #ddd;
			
			display: flex;
			align-items: center;
			justify-content: center;
		}
		
		.success-info-1{
			// padding-left: 150px;
			padding-top: 50px;
			font-size: 16px;
			font-weight: bold;
		}
		.success-info-2{
			// padding-left: 150px;
			padding-top: 20px;
			font-size: 15px;
			max-width: 900px;
			line-height: 22px;
			color: #666;
		}
		.success-info-3{
			margin-bottom: 10px;
		}
		.btn-wraper{
			// padding-left: 300px;
			margin-top: 40px;
			text-align: center;
		}
		
	}
</style>
