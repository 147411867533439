<template>
	<div class="base-info">
		
		<div class="title">营业执照
			<span class="form-item-tip" style="margin-left:15px">(营业执照需要和小程序认证时使用的营业执照一致，否则将
				<span style="color:#f56c6c">进件失败</span>
				；小程序即您在企店商城后台→销售渠道→微信小程序中绑定成功的小程序)</span>
		</div>
		
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px">
			<el-form-item label="商户名称:" prop="MerchantName">
				<el-input v-model="ruleForm.MerchantName" style="width: 350px;" maxlength="32" placeholder="请输入营业执照上的名称"></el-input>
			</el-form-item>
			<el-form-item label="商户简称:" prop="MerchantAbbrName">
				<el-input v-model="ruleForm.MerchantAbbrName" style="width: 350px;" maxlength="10" placeholder="请输入简称, 不超过10个字"></el-input>
				<div class="form-item-tip">
					后续将会展示在微信支付成功的页面上，一个月仅支持修改1次，
					<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnPNCw03Y3mtHZh9r3w7Lv18#SHwHkO" target="_blank" style="font-size: 12px;">查看示例</el-link>
				</div>
			</el-form-item>
			<el-form-item label="统一社会信用代码:" prop="LicenseCode">
				<el-input v-model="ruleForm.LicenseCode" style="width: 350px;" maxlength="18" placeholder="请输入营业执照上的18位统一社会信用代码"></el-input>
			</el-form-item>
			<el-form-item label="经营地址:" prop="AddressArr">
				<el-cascader style="width: 300px;" :options="dataList" clearable placeholder="请选择省市区"
					@change="handleAddressChange" v-model="ruleForm.AddressArr" maxlength="100" />
			</el-form-item>
			<el-form-item label="" prop="BusinessAddressDetail">
				<el-input type="textarea" v-model="ruleForm.BusinessAddressDetail" style="width: 350px;line-height: 20px;" placeholder="请填写营业执照上的地址" :maxlength="maxlength" show-word-limit></el-input>
			</el-form-item>
			<el-form-item label="商户电话:" prop="MerchantTel">
				<el-input v-model="ruleForm.MerchantTelAreaCode" style="width: 100px;" maxlength="5" placeholder="区号"></el-input>
				-
				<el-input v-model.number="ruleForm.MerchantTel" style="width: 240px;" maxlength="11" placeholder="请输入商户电话或手机"></el-input>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	
	import axios from 'axios'
	
	export default {
		props: {
			configData: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		data() {
			
			var checkTal = (rule, value, callback) => {
				console.log(this.ruleForm.MerchantTelAreaCode)
				if (this.ruleForm.MerchantTelAreaCode && isNaN(this.ruleForm.MerchantTelAreaCode)) {
					return callback(new Error('请输入正确的区号'));
				}else if (!this.ruleForm.MerchantTel){
					return callback(new Error('请输入商户电话'));
				}else if (isNaN(this.ruleForm.MerchantTel)){
					return callback(new Error('商户电话最多输入11位数字'));
				}else {
					return callback();
				}
			};
			
			var checkAddress = (rule, value, callback) => {
				if (!this.ruleForm.BusinessAddressDetail) {
					return callback(new Error('请输入经营所在地详细地址'));
				}else if (this.ruleForm.BusinessAddressDetail.length > this.maxlength){
					return callback(new Error('详细地址字数超出限制'));
				}else {
					return callback();
				}
			};
			
			return {
				dataList: [],
				
				ruleForm: {
					MerchantName: '',
					MerchantAbbrName: '',
					LicenseCode: '',
					BusinessAddressProvinceCode: '',
					BusinessAddressProvince : '',
					BusinessAddressCityCode: '',
					BusinessAddressCity: '',
					BusinessAddressDistrictCode: '',
					BusinessAddressDistrict: '',
					BusinessAddressDetail: '',
					AddressArr: [],
					
					MerchantTelAreaCode: '',
					MerchantTel: '',
				},
				rules: {
					MerchantName: [{
						required: true,
						trigger: 'blur',
						message: '请输入商户名称'
					}],
					MerchantAbbrName: [{
						required: true,
						trigger: 'blur',
						message: '请输入商户简称'
					}],
					LicenseCode: [{
						required: true,
						trigger: 'blur',
						message: '请输入18位统一社会信用代码'
					}],
					AddressArr: [{
						required: true,
						message: '请选择地址',
						trigger: 'change'
					}],
					BusinessAddressDetail: [{
						required: true,
						trigger: 'blur',
						validator: checkAddress
					}],
					MerchantTel: [{
						required: true,
						trigger: 'blur',
						validator: checkTal
					}]
				}
			}
		},
		computed:{
			maxlength(){
				if (!this.ruleForm.BusinessAddressProvince) return 60;
				
				return 60-(this.ruleForm.BusinessAddressProvince.length + this.ruleForm.BusinessAddressCity.length + this.ruleForm.BusinessAddressDistrict.length) 
			}
		},
		watch: {
			configData(){
				this.init();
			}
		},
		created() {
			this.getDataList();
			this.init();
		},
		methods: {
			init(){
				if (this.configData.MerchantTel){
					this.configData.MerchantTel = +this.configData.MerchantTel;
				}
				
				for (let item in this.configData){
					if (item === 'AddressArr'){
						if (this.configData[item] && this.configData[item].length){
							this.ruleForm[item] = this.configData[item];
						}
					}else{
						this.ruleForm[item] = this.configData[item];
					}
					
				}
			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('http://cdn.dkycn.cn/JSON/melyshop/ruzhu.json?v='+new Date().getTime(), {})
					.then(function(response) {
						var dt = response.data;
						var array = [];
						for (let item in dt){
							
							var obj = {
								value: dt[item].val,
								label: item,
								children: []
							};
							
							var array1 = dt[item].items;
							for (let item1 in array1){
								var obj1 = {
									value: array1[item1].val,
									label: item1,
									children: []
								};
								
								var array2 = array1[item1].items;
								
								for (let item2 in array2){
									var obj2 = {
										value: array2[item2],
										label: item2
									};
									obj1.children.push(obj2)
								}
								
								obj.children.push(obj1)
							}
							
							array.push(obj)
						}
						// console.log(array)
						that.dataList = array
					})
					.catch(function(error) {})
			},
			handleAddressChange() {
				if (!this.ruleForm.AddressArr.length) return;
				this.ruleForm.BusinessAddressProvinceCode = this.ruleForm.AddressArr[0]
				this.ruleForm.BusinessAddressCityCode = this.ruleForm.AddressArr[1]
				this.ruleForm.BusinessAddressDistrictCode = this.ruleForm.AddressArr[2]
				this.dataList.map(item => {
					if (item.value == this.ruleForm.BusinessAddressProvinceCode) {
						this.ruleForm.BusinessAddressProvince = item.label
						item.children.map(items => {
							if (items.value == this.ruleForm.BusinessAddressCityCode) {
								this.ruleForm.BusinessAddressCity = items.label
								items.children.map(lastitems => {
									if (lastitems.value == this.ruleForm.BusinessAddressDistrictCode) {
										this.ruleForm.BusinessAddressDistrict = lastitems.label
									}
								})
							}
						})
					}
				})
			},
			submit(callback){
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						callback && callback(true, this.ruleForm);
					} else {
						callback && callback(false);
				  }
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.base-info{
		padding-top: 30px;
	}
	.form-item-tip{
		font-size: 12px;
		margin-top: 3px;
		color: #999;
		height: 20px;
		line-height: 20px;
	}
	.title{
		height: 20px;
		line-height: 20px;
		padding-left: 5px;
		border-left: 3px solid #61afff;
		margin-left: 40px;
		margin-bottom: 20px;
		font-size: 15px;
	}
</style>
