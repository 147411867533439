<template>
  <div class="base-info">
    <div class="title">企业法人信息
      <span class="form-item-tip" style="margin-left:15px">(请填写企业法人信息，不可使用运营者或其他人的信息，否则将
				<span style="color:#f56c6c">进件失败</span>)
      </span>
    </div>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="160px"
    >
      <el-form-item label="法人姓名:" prop="CorporationName">
        <el-input
          v-model="ruleForm.CorporationName"
          style="width: 350px;"
          maxlength="16"
          placeholder="请输入企业法人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="法人手机号:" prop="CorporationPhone">
        <el-input
          v-model="ruleForm.CorporationPhone"
          style="width: 350px;"
          maxlength="11"
          placeholder="请输入11位手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="法人证件号码:" prop="CorporationCardId">
        <el-input
          v-model="ruleForm.CorporationCardId"
          style="width: 350px;"
          maxlength="18"
          placeholder="请输入法人身份证号"
        ></el-input>
      </el-form-item>
      <div style="display:flex;flex-direction:row">
        <el-form-item
          v-show="!ruleForm.longtime"
          label="证件有效期:"
          :prop="ruleForm.longtime ? 'nocheck' : 'CorporationCardIdStartDate'"
          :rules="
            ruleForm.longtime
              ? [{ required: false }]
              : rules.CorporationCardIdStartDate
          "
         >
          <el-date-picker
            v-model="ruleForm.CorporationCardIdStartDate"
            style="width: 170px;"
            type="date"
            placeholder="身份证开始日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          ~
          <el-date-picker
            v-model="ruleForm.CorporationCardIdEndDate"
            style="width: 170px;"
            type="date"
            placeholder="身份证结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-checkbox
          v-show="!ruleForm.longtime"
          style="margin:10px 15px"
          v-model="ruleForm.longtime"
          >长期有效</el-checkbox
         >
        <el-form-item
					class = "errortips"
          label="证件有效期:"
          v-show="ruleForm.longtime"
          :prop="!ruleForm.longtime ? 'nocheck' : 'CorporationCardIdStartDate'"
          :rules="
            !ruleForm.longtime
              ? [{ required: false }]
              : rules.CorporationCardIdStartDate
          "
          style="display:flex;flex-direction:row"
         >
          <el-date-picker
            style="width:170px;margin-left:-160px;"
            v-model="ruleForm.CorporationCardIdStartDate"
            placeholder="身份证开始日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          ~
          <el-checkbox v-model="ruleForm.longtime">长期有效</el-checkbox>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { validatePhone, validatePhoneIdentityIDCard } from "@/utils/validate";

export default {
  props: {
    configData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    var nocheck = (rule, value, callback) => {
      callback();
    };
    var checkPhone = (rule, value, callback) => {
      if (!validatePhone(this.ruleForm[rule.field])) {
        return callback(new Error("请输入法人的11位手机号"));
      } else {
        return callback();
      }
    };
    var checkCardId = (rule, value, callback) => {
      if (!this.ruleForm[rule.field]) {
        return callback(new Error("请输入法人证件号码"));
      } else if (!validatePhoneIdentityIDCard(this.ruleForm[rule.field])) {
        return callback(new Error("法人证件号码格式错误，请重新输入"));
      } else {
        return callback();
      }
    };
    var checkDate = (rule, value, callback) => {
      if (
        !this.ruleForm.CorporationCardIdStartDate ||
        !this.ruleForm.CorporationCardIdEndDate
      ) {
        return callback(new Error("请完善法人证件有效期"));
      } else if (
        new Date(this.ruleForm.CorporationCardIdStartDate).getTime() >
        new Date(this.ruleForm.CorporationCardIdEndDate).getTime()
      ) {
        return callback(new Error("证件有效期选择有误，请重新选择日期"));
      } else {
        return callback();
      }
    };

    return {
      ruleForm: {
        CorporationName: "",
        CorporationPhone: "",
        CorporationCardId: "",
        CorporationCardIdStartDate: "",
        CorporationCardIdEndDate: "",
        longtime: false,
      },
      rules: {
        CorporationName: [
          {
            required: true,
            trigger: "blur",
            message: "请输入法人姓名",
          },
        ],
        CorporationPhone: [
          {
            required: true,
            trigger: "blur",
            validator: checkPhone,
          },
        ],
        CorporationCardId: [
          {
            required: true,
            trigger: "blur",
            validator: checkCardId,
          },
        ],
        CorporationCardIdStartDate: [
          {
            required: true,
            trigger: "change",
            validator: checkDate,
          },
        ],
        CorporationCardIdEndDate: [
          {
            required: true,
            trigger: "change",
            validator: checkDate,
          },
        ],
      },
    };
  },
  watch: {
    configData() {
      this.init();
    },
    "ruleForm.longtime": {
      handler: function(a, b) {
        if (a) {
          this.ruleForm.CorporationCardIdEndDate = this.conversion('2099-12-31')
					// console.log(this.ruleForm.CorporationCardIdEndDate)
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    conversion(strDate) {
      var date = eval(
        "new Date(" +
          strDate
            .replace(/\d+(?=-[^-]+$)/, function(a) {
              return parseInt(a, 10) - 1;
            })
            .match(/\d+/g) +
          ")"
      );
      return date;
    },
    init() {
      var start = this.configData.CorporationCardIdStartDate;
      var end = this.configData.CorporationCardIdEndDate;
      // console.log(this.configData.CorporationCardIdStartDate)
      // if (start && start.split("-").length > 1) {
      //   this.configData.CorporationCardIdStartDate = this.formatDate(start);
      // }

      // if (end && end.split("-").length > 1) {
      //   this.configData.CorporationCardIdEndDate = this.formatDate(end);
      // }

      for (let item in this.configData) {
        if (this.configData[item]) {
          this.ruleForm[item] = this.configData[item];
        }
      }
    },
    formatDate(date) {
      var year = date.split(" ")[0].split("-")[0];
      var month = +date.split(" ")[0].split("-")[1] - 1;
      var day = date.split(" ")[0].split("-")[2];
      var hour = date.split(" ")[1].split(":")[0];
      var minute = date.split(" ")[1].split(":")[1];
      var second = date.split(" ")[1].split(":")[2];

      return new Date(year, month, day, hour, minute, second);
    },
    submit(callback) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          callback && callback(true, this.ruleForm);
        } else {
          callback && callback(false);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.base-info {
  padding-top: 30px;
}
.form-item-tip {
  font-size: 12px;
  margin-top: 3px;
  color: #999;
  height: 20px;
  line-height: 20px;
}
.title {
  height: 20px;
  line-height: 20px;
  padding-left: 5px;
  border-left: 3px solid #61afff;
  margin-left: 40px;
  margin-bottom: 20px;
  font-size: 15px;
}
.errortips ::v-deep .el-form-item__error{
	left:-160px
}
</style>
