import { render, staticRenderFns } from "./submitInformation.vue?vue&type=template&id=dee89c1c&scoped=true"
import script from "./submitInformation.vue?vue&type=script&lang=js"
export * from "./submitInformation.vue?vue&type=script&lang=js"
import style0 from "./submitInformation.vue?vue&type=style&index=0&id=dee89c1c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dee89c1c",
  null
  
)

export default component.exports